const ErrorPage = () => {
	return (
		<div className='pl-4 w-full min-h-[100vh] bg-back-color py-4'>
			<h1 className='text-black text-2xl'>
				Error, the page you are looking for does not exist!
			</h1>
		</div>
	);
};

export default ErrorPage;
