import MailIcon from '@mui/icons-material/Mail';
import TimerIcon from '@mui/icons-material/Timer';
import PollIcon from '@mui/icons-material/Poll';
import PersonIcon from '@mui/icons-material/Person';
import { Restaurant } from '@mui/icons-material';
import ListIcon from '@mui/icons-material/List';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';

// each entry defines the requirements that have to be true to route. 
// add a special property for operator tools "isTool" then in sidebar check for operatorTools in path
export const sidebar = [
	{
		icon: MailIcon,
		text: 'Survey Invites',
		path: '/survey/invites',
		selected: 'Invites',
		isAdmin: false,
	},
	{
		icon: TimerIcon,
		text: 'In Progress',
		path: '/survey/respond',
		selected: 'Respond',
		isAdmin: false,
	},
	{
		icon: PersonIcon,
		text: 'Account',
		path: '/diner/settings',
		selected: 'Settings',
		isAdmin: false,
	},
	{
		icon: PollIcon,
		text: 'Launch a Survey',
		path: '/admin/survey/manage',
		selected: 'Manage',
		isAdmin: true,
	},
	{
		icon: ListIcon,
		text: 'Survey Templates',
		path: '/admin/survey/templates',
		selected: 'Templates',
		isAdmin: true,
	},
	{
		icon: StackedLineChartIcon,
		text: 'Survey Data',
		path: '/admin/survey/statistics',
		selected: 'Statistics',
		isAdmin: true,
	},
	{
		icon: Restaurant,
		text: 'My Restaurants',
		path: '/admin/restaurant/manage',
		selected: 'Restaurants',
		isAdmin: true,
	},
];
