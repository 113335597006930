import { HashRouter, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import Dashboard from '../dashboard/Dashboard';
import SurveyTest from '../survey/SurveyTest';
import SurveyTemplates from '../survey/manage/survey/SurveyTemplates';
import ManageRestaurants from '../survey/manage/restaurant/ManageRestaurants';
import Restaurant from '../survey/Restaurant';
import Survey from '../survey/Survey';
import Settings from '../diner/Settings';
import { useEffect } from 'react';
import { requestForToken } from '../../firebase';
import { useAppContext } from '../../context/AppContext';
import SurveyInvitations from '../survey/invites/SurveyInvitations.jsx';
import ErrorPage from './ErrorPage.jsx';
import ResetPassword from '../userAuth/ResetPassword.jsx';
import ManageSurveys from '../survey/manage/survey/ManageSurveys';
import SurveyStatistics from '../survey/manage/survey/SurveyStatistics';
import SurveyResponsesView from '../survey/manage/survey/SurveyResponsesView.jsx';
import OperatorTools from '../operatorTools/OperatorTools.jsx';

const Router = () => {
	const { user } = useAppContext();
	useEffect(() => {
	requestForToken(user.userId);
	}, []);
	return (
		<HashRouter>
			<Routes>
				<Route path='/' element={<Layout />}>
					<Route index element={<Dashboard />} />
					<Route path='/survey' element={<SurveyTest />} />
					{user.superadmin && (
						<Route path='/operatortools' element={<OperatorTools />} />
					)}
					{user.admin && (
						<Route path='/admin/survey/:id' element={<Survey />} />
					)}
					{user.admin && (
						<Route path='/admin/survey/manage' element={<ManageSurveys />} />
					)}
					{user.admin && (
						<Route
							path='/admin/survey/templates'
							element={<SurveyTemplates />}
						/>
					)}
					{user.admin && (
						<Route path='/admin/restaurant/:id' element={<Restaurant />} />
					)}
					{user.admin && (
						<Route
							path='/admin/restaurant/manage'
							element={<ManageRestaurants />}
						/>
					)}
					{user.admin && (
						<Route
							path='/admin/survey/statistics'
							element={<SurveyStatistics />}
						/>
					)}
					{user.admin && (
						<Route
							path='/admin/survey/responses/:recurringSurveyId'
							element={<SurveyResponsesView />}
						/>
					)}
					{!user.admin && <Route path='/survey/respond' element={<Survey />} />}
					{!user.admin && (
						<Route path='/survey/invites' element={<SurveyInvitations />} />
					)}
					{!user.admin && (
						<Route path='/diner/settings' element={<Settings />} />
					)}
					{!user && (
						<Route path='/reset/password/:token' element={<ResetPassword />} />
					)}
					<Route path='*' element={<ErrorPage />} />
					{/* <Route path='/projects' element={<ProjectsComponent />} />
					<Route path='/project/:id' element={<ProjectDetails />} />
					<Route path='/ticket/:id' element={<TicketDetails />} />
					<Route path='/employees' element={<EmployeesComponent />} />
					<Route path='*' element={<common.ErrorPageComponent />} /> */}
				</Route>
			</Routes>
		</HashRouter>
	);
};

export default Router;
